
import {dateDiff, getTodayFormatDate } from './utils';

export const GLOBAL_KEYS = {
  EXIST_KEY: 'HAS_GIFT',
  NAME_KEY: 'GIFT_NAME',
  IMAGE_KEY: 'GIFT_IMAGE_URL',
};

// 是否允许对方在抽奖结束后重新抽奖
export const IS_SHOW_RESTART = false;

// 首页配置
export const homeConfig = {
  // 标题
  title: '老洪♥老妞',
  // 留言，数组的每一项代表一行
  messages: [
    { key: 'a', wording: '今天是相识第' + dateDiff (getTodayFormatDate(),"2019-12-14") + '天', },
    { key: 's', wording: '今天是婚后第' + dateDiff (getTodayFormatDate(),"2021-07-30") + '天', },
    // { key: 'd', wording: '然后，这里是第三行文案', },
  ],
  // 最终解释权归属人
  owner: '老妞♥老洪',
  // 抽奖转盘的动画时间，单位毫秒
  timeout: 5000,
  // 指定赠送的礼物的索引（第几个礼物中奖），取值范围 0-8
  targetGiftIndex: null,
};

// 礼物清单
// 字段 key 不用改，只要保证每个礼物的 key 字段是不同的即可
// 图片放在 public/images 中，建议是正方形，否则影响视觉体验
// name 是礼物的全称；alias 是礼物的简称（用来显示在抽奖方格里）
export const gifts = [
  { key: 'q', name: '圣罗兰口红套餐', alias: '罗兰', image: '/images/1.png',  description: '「着我装者, 着我妆」' },
  { key: 'w', name: '路易·威登包包', alias: 'LV', image: '/images/2.png',  description: '每个故事都蕴含着一段标致的旅程”' },
  { key: 'e', name: '兰蔻小黑瓶套装', alias: '小黑瓶套装', image: '/images/3.png',  description: '「强维稳，快修护」' },
  { key: 'r', name: '520红包', alias: '520', image: '/images/6.png',  description: '“想给你唱一百首情歌”' },
  { key: 't', name: 'Dior星空套装', alias: 'Dior星空', image: '/images/5.png',  description: '「百变唇妆，精美雕琢」' },
  { key: 'y', name: '1314红包', alias: '1314', image: '/images/6.png',  description: '“从今往后，我都会在你旁边”' },
  { key: 'u', name: '购物券：¥10', alias: 'UR', image: '/images/7.png',  description: '“买！买！买！”' },
  { key: 'i', name: '美甲套餐', alias: '美甲', image: '/images/8.png',  description: '「不同款式需求，全面满足」' },
];
